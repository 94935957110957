import React from "react";

function RowImages({ images }) {
  if (!images) return null;
  return (
    <div className="row__images">
      {images.map((image, i) => (
        <img
          key={image.name}
          className="row__img"
          src={`data:image/png;base64,${image.data}`}
          alt={image.name}
        />
      ))}
    </div>
  );
}

export default RowImages;
