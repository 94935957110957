import React, { useEffect } from "react";
import { Callout, Intent } from "@blueprintjs/core";
import api from "../api";

export function Error({ title, children }) {
  // if we're here it's possible there is something out of
  // sync in the local state, or that by having an application
  // error we have put it in an invalid state. So lets clear
  // it and have the user start new.
  useEffect(() => localStorage.clear() && api.logout(), []);
  return (
    <Callout id="app-error" title={title} intent={Intent.DANGER}>
      {children}
    </Callout>
  );
}

export default Error;
