import { useEffect, useState } from "react";

import api from "../../api";
import { parseTokenFromUrl } from "../../utils/environment";

const LOCAL_STORAGE = "tars-debug-store";

export function useDebugInformation() {
  const [rows, setRows] = useState(null);
  const [error, setError] = useState(false);
  const [tokenError, setTokenError] = useState(false);
  const token = parseTokenFromUrl();
  const storedFarmhouseToken = api.loadAuthorizationHeader();

  useEffect(() => {
    setTokenError(false);
    setError(false);
    setRows(null);
    api
      .load_information(token)
      .then((response) => {
        setRows(response.data.rows);
        window.localStorage.setItem(
          LOCAL_STORAGE,
          JSON.stringify(response.data)
        );
      })
      .catch((error) => {
        console.error(error);
        const detail = error?.response?.data?.detail;
        if (detail && detail.includes("token")) {
          setTokenError(true);
        } else {
          setError(true);
        }
      });
  }, [token, storedFarmhouseToken]);
  return { rows, error, tokenError };
}

export function useGoogleAuth() {
  const [authLoading, setAuthLoading] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [user, setUser] = useState(null);
  const storedFarmhouseToken = api.loadAuthorizationHeader();

  function loadUserProfile() {
    const tokenExists = api.loadAuthorizationHeader();
    if (!tokenExists) return;

    api.userProfile().then((response) => {
      setUser(response.data);
      window.history.replaceState(null, "", "/");
    }).catch(() => {
      console.error("Failed to retrieve user information")
    })
  }

  useEffect(() => {
    const url = new URL(window.location.href.replace("/#", "/?"));
    const accessToken = url.searchParams.get("access_token");
    if (accessToken) {
      console.log(`Logging into API using google access token: ${accessToken}`);
      setAuthLoading(true);
      setAuthError(false);
      api
        .loginWithGoogle(accessToken)
        .then((response) => {
          api.setAuthorizationHeader(response.data.key);
          loadUserProfile()
          setAuthLoading(false);
          setAuthError(false);
        })
        .catch(() => {
          setAuthLoading(false);
          setAuthError(true);
        });
    } else {
      loadUserProfile()
    }
  }, [storedFarmhouseToken]);
  return { user, authLoading, authError };
}
