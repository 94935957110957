import React, { useState, useRef, useEffect } from "react";
import { Card } from "@blueprintjs/core";
import { parseTokenFromUrl } from "../utils/environment";
import { rowMatchesFailureCode, rowMatchesParamsExactly } from '../utils/params';

import api from "../api";
import RowContent from "./RowContent";

function findUniqueImages(imageList) {
  const uniqueImages = [];
  imageList.forEach((imgData) => {
    if (uniqueImages.find((img) => img.name === imgData.name)) return;
    uniqueImages.push(imgData);
  });
  return uniqueImages;
}


function Row({ row, exactMatchExists, showEnglish, allOpen }) {
  const rowRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState(null);
  const [imagesError, setImagesError] = useState(null);
  const token = parseTokenFromUrl();

  useEffect(() => {
    function scrollIntoView() {
      rowRef.current.scrollIntoView();
      setIsOpen(true);
    }

    if (exactMatchExists && rowMatchesParamsExactly(row)) {
      scrollIntoView()
    } else if (!exactMatchExists && rowMatchesFailureCode(row)) {
      scrollIntoView()
    }
  }, []);

  useEffect(() => {
    if (isOpen !== allOpen) {
      setIsOpen(allOpen);
    }
  }, [allOpen]);

  useEffect(() => {
    if (!isOpen) return;
    if (images) return;

    setImagesError(false);
    setImages(null);
    api
      .load_images(token, row.images)
      .then((response) => {
        setImages(findUniqueImages(response.data.images));
      })
      .catch((error) => {
        setImagesError(true);
      });
  }, [isOpen, token]);

  const sharedProps = { setIsOpen, isOpen, row, images };
  return (
    <Card
      ref={rowRef}
      id={`${row.code}-card`}
      interactive={!isOpen}
      onClick={isOpen ? null : () => setIsOpen(true)}
    >
      <RowContent key={row.code} visible={showEnglish} {...sharedProps} />
      <RowContent
        key={`${row.code}-t`}
        visible={!showEnglish}
        translated
        {...sharedProps}
      />
    </Card>
  );
}

export default Row;
