import React from 'react';
import { Alignment, Button, Navbar } from '@blueprintjs/core';
import api from '../api';

function Nav({
  handleToggleRows,
  isBrowserEnglish,
  showEnglish,
  setShowEnglish,
  user,
  children,
}) {
  function toggleTranslations() {
    setShowEnglish(!showEnglish);
  }

  function handleLogin() {
    const url = api.googleRedirectUri();
    window.location.href = url; // redirect to goole auth
  }

  function handleLogout() {
    api.logout();
    window.location.reload();
  }

  return (
    <Navbar>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading>TARS Debugging Guide</Navbar.Heading>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        {user && children}
        {user && (
          <Button
            className="bp5-minimal"
            icon="maximize"
            text="Toggle All Rows"
            onClick={handleToggleRows}
          />
        )}
        <Navbar.Divider />
        {!isBrowserEnglish && (
          <Button
            className="notranslate bp5-minimal nav__translate"
            onClick={toggleTranslations}
            icon="translate"
            text={showEnglish ? navigator.language : 'English'}
          />
        )}
        <Button
          className="bp5-minimal"
          icon="user"
          text={user ? 'Logout' : 'login'}
          onClick={user ? handleLogout : handleLogin}
        />
      </Navbar.Group>
    </Navbar>
  );
}

export default Nav;
