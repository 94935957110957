import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import loadEnvironment from "./utils/environment";

class FarmhouseApi {
  constructor(host, googleClientId, googleCallbackUrl) {
    this.host = host;
    this.googleClientId = googleClientId;
    this.googleCallbackUrl = googleCallbackUrl;
    this.loadAuthorizationHeader();
  }

  load_information(token) {
    return axios.get(`${this.host}/tars/failure_code_information/${token}/`);
  }

  load_images(token, image_names) {
    const encodedImageNames = encodeURIComponent(image_names);
    return axios.get(
      `${this.host}/tars/failure_code_images/${token}/?images=${encodedImageNames}`
    );
  }

  googleRedirectUri() {
    const stateToken = uuidv4();
    const queryString = `included_grant_scopes=True&scope=openid email profile&state=${stateToken}&redirect_uri=${this.googleCallbackUrl}&response_type=token&client_id=${this.googleClientId}`;
    return `https://accounts.google.com/o/oauth2/v2/auth?${queryString}`;
  }

  loginWithGoogle(accessToken) {
    return axios.post(`${this.host}/accounts/google/`, {
      access_token: accessToken,
      code: uuidv4(),
    });
  }

  userProfile(host) {
    return axios.get(`${this.host}/accounts/me/`);
  }

  setAuthorizationHeader(accessToken) {
    const authorizationHeader = `Bearer ${accessToken}`;
    console.log(`Setting authorization header to ${authorizationHeader}`);
    window.localStorage.setItem("tars-debugging-token", accessToken);
    axios.defaults.headers.common.Authorization = authorizationHeader;
  }

  loadAuthorizationHeader() {
    const storedAccessToken = window.localStorage.getItem(
      "tars-debugging-token"
    );
    if (storedAccessToken) {
      const authorizationHeader = `Bearer ${storedAccessToken}`;
      axios.defaults.headers.common.Authorization = authorizationHeader;
      return storedAccessToken;
    }
  }

  logout() {
    console.log("Clearing authorization token");
    window.localStorage.removeItem("tars-debugging-token");
    axios.defaults.headers.common = {};
  }
}

const environment = loadEnvironment();

export default new FarmhouseApi(
  environment.host,
  environment.googleClientId,
  environment.googleCallbackUrl
);
