import React from "react";

import { Intent, Spinner } from "@blueprintjs/core";

function RowImageLoader() {
  return (
    <div className="row__images">
      <Spinner className="row__loader" size={75} intent={Intent.PRIMARY} />
    </div>
  );
}

export default RowImageLoader;
