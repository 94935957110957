import React from "react";

import { Error } from "../Error";

function AccessError() {
  return (
    <Error title="Error accessing data">
      <p>
        There was an error retrieving debug information. Login with google or
        verify you have the correct URL and authentication permissions. Contact
        Formlabs for support if the issue persists.
      </p>
    </Error>
  );
}

export default AccessError;
