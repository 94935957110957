/**
 * label - what is displayed to the user
 * host - which API we should be interacting with
 * googleClientId - the client id of the oauth2 application registered with the host
 * googleCallbackUrl - where google should redirect their oauth2 callback to
 */

const STAGING_VARIABLES = {
  label: "staging",
  host: "https://farmhouse-staging.factory.pub.dev.gcp.formlabs.cloud/farmhouse",
  googleClientId:
    "566342689181-eqp6s7eptpg8mjf3bgkvil5ua3058op7.apps.googleusercontent.com",
  googleCallbackUrl: `${window.location.origin}/auth/google/`,
};

const PRODUCTION_VARIABLES = {
  label: "production",
  host: "https://farmhouse.factory.pub.prod.gcp.formlabs.cloud/farmhouse",
  googleClientId:
    "647568488035-d14gkn17ecsiucn1e8t6059bfmld9k7p.apps.googleusercontent.com",
  googleCallbackUrl: `${window.location.origin}/auth/google/`,
};

const LOCAL_VARIABLES = {
  label: "local",
  host: "http://localhost:8000",
  googleClientId:
    "852071883928-lf5l6fahvk96j03pbraes46uiemdmejb.apps.googleusercontent.com",
  googleCallbackUrl: "http://localhost:3000/auth/google/",
};

// don't show the local option unless we are actually in
// local development.

function loadEnvironment() {
  if (window.location.href.includes("localhost")) {
    return LOCAL_VARIABLES;
  }
  if (window.location.href.includes("tars-debugging.factory.priv.dev.gcp")) {
    return STAGING_VARIABLES;
  }
  return PRODUCTION_VARIABLES;
}

export function parseTokenFromUrl() {
  const url = new URL(window.location.href);
  return url.searchParams.get("token");
}

export default loadEnvironment;
