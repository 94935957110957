import React from "react";

import { Error } from "../Error";

function AuthError() {
  return (
    <Error title="Error executing google authentication">
      <p>
        There was an error while logging you in. Please re-load the page. If the
        issue persists contact Formlabs for support.
      </p>
    </Error>
  );
}

export default AuthError;
