import React from "react";

import { Error } from "../Error";

function ApiError() {
  return (
    <Error title="Error while calling API">
      <p>
        There was an error communicating with Farmhouse. Please re-load the
        page. If the issue persists contact Formlabs for support.
      </p>
    </Error>
  );
}

export default ApiError;
