import React from "react";
import { Collapse } from "@blueprintjs/core";

import LanguageButton from "./LanguageButton";
import RowText from "./RowText";
import RowImages from "./RowImages";
import RowImageLoader from "./RowImageLoader";

function RowContent({ row, isOpen, setIsOpen, visible, translated, images }) {
  const rowModifier = visible ? "--visible" : "--notvisible";
  const translateModifier = translated ? "" : " notranslate";
  const className = `row__container row__container${rowModifier}${translateModifier}`;

  return (
    <div className={className}>
      <div className="row__header">
        <div className="row__text">
          <h3 className="notranslate row__title">{row.code}</h3>
          <div className="row__desc">{row.message}</div>
        </div>
        <LanguageButton isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
      <Collapse isOpen={isOpen} style={{ width: "100%" }}>
        <div className="row__collapse">
          <div className="row__content">
            <RowText title="Causes" className="row__causes" data={row.causes} />
            <RowText title="Steps" className="row__steps" data={row.steps} />
          </div>
          {images ? <RowImages images={images} /> : <RowImageLoader />}
        </div>
      </Collapse>
    </div>
  );
}

export default RowContent;
