import React from 'react';

function SelectFilter({ prompt, products, handleSelect, product }) {
  function onChange(e) {
    e.preventDefault();
    handleSelect(e.target.value);
  }

  return (
    <div class="bp5-html-select selectfilter">
      <select defaultValue={null} onChange={onChange} value={product}>
        <option value="">{prompt}</option>
        {products.map((product) => (
          <option key={product} value={product}>
            {product}
          </option>
        ))}
      </select>
      <span class="bp5-icon bp5-icon-double-caret-vertical"></span>
    </div>
  );
}

export default SelectFilter;
