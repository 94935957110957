import React from "react";

function RowText({ title, className, data }) {
  console.log(data);
  return (
    <>
      <h4>{title}</h4>
      <div className={className}>
        {data && data.split("\n").map((s) => (
          <p key={s}>{s}</p>
        ))}
      </div>
    </>
  );
}

export default RowText;
