import React from "react";
import { Button } from "@blueprintjs/core";

function LanguageButton({ isOpen, setIsOpen }) {
  return (
    <div>
      {isOpen && (
        <Button intent="danger" icon="cross" onClick={() => setIsOpen(false)}>
          Close
        </Button>
      )}
    </div>
  );
}

export default LanguageButton;
