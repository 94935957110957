
function extractUrlParam(paramName) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const param = urlParams.get(paramName)
    if (param) {
      return param.toLowerCase().trim();
    }
    return null;
}

const TARGET_CODE = extractUrlParam('code');
const TARGET_LABEL = extractUrlParam('testLabel');
  
function formattedRowCodeLabel(row) {
    const formattedCode = row.code.split(' ')[0].toLowerCase().trim();  // handle any context given to the code
    const formattedTestLabel = row.test_name ? row.test_name.toLowerCase().trim() : null;
    return [formattedCode, formattedTestLabel]
}

export function rowMatchesParamsExactly(row) {
    const [formattedCode, formattedlabel] = formattedRowCodeLabel(row)
    const codeMatches = TARGET_CODE === formattedCode;
    const labelMatches = formattedlabel === TARGET_LABEL;
    return codeMatches && labelMatches;
}

export function rowMatchesFailureCode(row) {
    const [formattedCode, _] = formattedRowCodeLabel(row)
    return TARGET_CODE === formattedCode;
}